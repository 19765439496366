import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MainView from "@/views/MainView.vue";
import WalletView from "@/views/WalletView.vue";
import PromocodeView from "@/views/PromocodeView.vue";
import GamesView from "@/views/GamesView.vue";
import TopView from "@/views/TopView.vue";
import FaqView from "@/views/FaqView.vue";
import DepositView from '@/views/DepositView.vue';
import FormView from '@/views/FormView.vue';
import TgLoginView from '@/views/TgLoginView.vue';
import FirstDragonView from '@/views/FirstDragonView.vue';
import OtzivyView from '@/views/OtzivyView.vue';
import PaymentView from '@/views/PaymentView.vue';
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "main",
    component: MainView,
  },
  {
    path: "/wallet",
    name: "wallet",
    component: WalletView,
  },
  {
    path: "/promocode",
    name: "promocode",
    component: PromocodeView,
  },
  {
    path: "/games",
    name: "games",
    component: GamesView,
  },
  {
    path: "/top",
    name: "top",
    component: TopView,
  },
  {
    path: "/faq",
    name: "faq",
    component: FaqView,
  },
  {
    path: "/deposit",
    name: "deposit",
    component: DepositView,
  },
  {
    path: "/form/deposit",
    name: "formDeposit",
    component: FormView,
  },
  {
    path: "/auth/tg-login/:token",
    name: "tgLogin",
    component: TgLoginView,
  },
  {
    path: "/firstDragon",
    name: "firstDragon",
    component: FirstDragonView,
  },
  {
    path: "/otzivy",
    name: "otzivy",
    component: OtzivyView,
  },
  {
    path: "/payment",
    name: "payment",
    component: PaymentView,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
