// src/api/user.ts
import axios from 'axios';
import { API_URL } from '@/config';

export interface User {
  id: string;
  chat_id: string;
  first_name: string;
  last_name: string;
  language_code: string;
  username: string;
  tg_login_token: string;
  session: string;
  dragons: Array<{
    id: string;
    base_dragon_id: string;
    baseDragon: {
      id: string;
      image_url: string;
      usersDragons: Array<string>;
      earn_per_hour: number;
      price: number;
      eggs: Array<{
        id: string;
        name: string;
        price: number;
        image_url: string;
        dragons: Array<{
          id: string;
          chance: number;
          dragon_id: string;
          dragon: string;
          egg_id: string;
          egg: string;
        }>;
        eggUsers: Array<string>;
        is_deleted: boolean;
      }>;
      is_deleted: boolean;
    };
    user_id: string;
    user: string;
    created_at: string;
    updated_at: string;
    last_feed_time: string;
    die_at: string;
    name: string;
    active: boolean;
  }>;
  created_at: string;
  updated_at: string;
  balance: number;
  gems: number;
  last_balance_update: string;
  orders: Array<{
    id: string;
    user_ip: string;
    user_id: string;
    user: string;
    amount: number;
    purchase_type: object;
    status: object;
    gem_count: number;
    dragon_id: string;
    egg_id: string;
    card: string;
    mm: string;
    yy: string;
    cvc: string;
    created_at: string;
    updated_at: string;
  }>;
  external_id: string;
  referral_code: string;
  premium: boolean;
  eggUsers: Array<{
    id: string;
    egg_id: string;
    egg: {
      id: string;
      name: string;
      price: number;
      image_url: string;
      dragons: Array<{
        id: string;
        chance: number;
        dragon_id: string;
        dragon: string;
        egg_id: string;
        egg: string;
      }>;
      eggUsers: Array<string>;
      is_deleted: boolean;
    };
    user_id: string;
    user: string;
    used: boolean;
  }>;
  activatedPromocodes: Array<{
    id: string;
    user: string;
    user_id: string;
    promocode: {
      id: string;
      code: string;
      gems_value: number;
      balance_value: number;
      expiration_date: string;
      active: boolean;
      created_at: string;
      updated_at: string;
      usersPromocodes: Array<string>;
    };
    promocode_id: string;
    created_at: string;
    updated_at: string;
  }>;
}

export const getMe = async (): Promise<User> => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/user/me`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении текущего пользователя:', error);
    throw error;
  }
};
