// src/api/dragon.ts
import axios from 'axios';
import { API_URL } from '@/config';

export interface Dragon {
  id: number;
  name: string;
  image_url: string;
  price: number;
  earn_per_hour: number;
  baseDragon: BaseDragon;
}

export interface BaseDragon {
  id: string;
  image_url: string;
  earn_per_hour: number;
  price: number;
  is_deleted: boolean;
}

export const getAllBaseDragons = async (): Promise<Dragon[]> => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/dragon/base-list`, {
      headers: {
        Authorization: `Bearer ${token}`,
       "Content-Type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": "*",
      },
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении базовых драконов:', error);
    throw error;
  }
};

export const createFreeDragon = async (data: any): Promise<Dragon> => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/dragon`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при создании дракона:', error);
    throw error;
  }
};

export const updateBaseDragon = async (data: any): Promise<Dragon> => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/dragon/base`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при обновлении базового дракона:', error);
    throw error;
  }
};

export const feedDragon = async (data: any): Promise<Dragon> => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.patch(`${API_URL}/dragon/feed`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при кормлении дракона:', error);
    throw error;
  }
};

export const getMyDragon = async (): Promise<{dragon: Dragon; refLink: string; usersOnline: number}> => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/dragon/my`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении моего дракона:', error);
    throw error;
  }
};

export const getMyDragons = async (): Promise<Dragon[]> => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/dragon/my/list`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении списка моих драконов:', error);
    throw error;
  }
};

export const updateDragon = async (id: number, data: any): Promise<Dragon> => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.patch(`${API_URL}/dragon/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при обновлении дракона:', error);
    throw error;
  }
};

// Другие функции...
