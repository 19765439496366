<template>
  <div class="tg-login">
    <div v-if="error" class="error">{{ errorMessage }}</div>
    <div v-if="loading" class="loading">Загрузка...</div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { loginFromTGBot } from '@/api/auth'; // Импортируем функцию из API

const route = useRoute();
const router = useRouter();

const loading = ref(true);
const error = ref(false);
const errorMessage = ref('');

onMounted(async () => {
  try {
    const token = route.params.token as string; // Убедитесь, что token - это строка
    const response = await loginFromTGBot(token); // Используем функцию из API

    // После успешной авторизации перенаправляем на главную
    if (response) {
      localStorage.setItem('token', response.token);
      router.push('/');
    }
  } catch (e) {
    error.value = true;
    errorMessage.value = e instanceof Error ? e.message : 'Произошла ошибка';
  } finally {
    loading.value = false;
  }
});
</script>

<style lang="scss" scoped>
.tg-login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  .error {
    color: var(--error);
    text-align: center;
  }

  .loading {
    color: var(--secondary-50);
  }
}
</style>
