// src/api/auth.ts
import axios from 'axios';
import { API_URL } from '@/config';

export interface LoginResponse {
  token: string;
  user: {
    id: number;
    name: string;
    email: string;
  };
}

export const loginFromTGBot = async (loginToken: string): Promise<LoginResponse> => {
  try {
    const response = await axios.get(`${API_URL}/auth/tg-login/${loginToken}/`, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: false
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error('Ошибка при логине:', error);
    throw error;
  }
};

export const testAuth = async (): Promise<any> => {
  try {
    const response = await axios.get(`${API_URL}/auth/test`);
    return response.data;
  } catch (error) {
    console.error('Ошибка при тестировании аутентификации:', error);
    throw error;
  }
};
