<template>
  <div class="title">
    <div>
      <h1 class="title-text">{{ title }}</h1>
      <router-link v-if="isClose" to="/"> <CloseSvg /> </router-link>
    </div>

    <h2 v-if="isText" class="subtitle">{{ subtitle }}</h2>
    <InputGroupComponent
      v-if="isInput"
      type="text"
      :title="subtitle"
      :placeholder="placeholderInput"
      v-model="inputValue"
    />

    <ButtonComponent v-if="isButton" @click="handleButtonClick"> Активировать </ButtonComponent>
  </div>
</template>
  <script setup lang="ts">
  import { ref, defineProps, watch, defineEmits } from 'vue';
  import CloseSvg from './Icons/CloseSvg.vue';
  import ButtonComponent from '@/components/ButtonComponent.vue';
  import InputGroupComponent from '@/components/InputGroupComponent.vue';
  import { activatePromocode } from '@/api/promocode';

  const props = defineProps({
    title: {
      type: String,
      default: 'Ваш кошелек',
    },
    subtitle: {
      type: String,
      default:
        '1 COIN = 5P\nНажмите кнопку обмен для вывода заработанных COIN на Рубли',
    },
    isText:{
      type: Boolean,
      default: false,
    },
    isInput: {
      type: Boolean,
      default: false,
    },
    isButton: {
      type: Boolean,
      default: false,
    },
    placeholderInput: {
      type: String,
      default: 'Введите промокод',
    },
    isClose: {
      type: Boolean,
      default: true,
    },
  });

  const emit = defineEmits(['update:modelValue']);

  const inputValue = ref('');

  watch(inputValue, (newValue) => {
    emit('update:modelValue', newValue);
  });

  const handleButtonClick = async () => {
    if (!inputValue.value.trim()) {
      console.error('Ошибка: поле промокода пустое');
      return;
    }

    try {
      const response = await activatePromocode({ code: inputValue.value.trim() });
      console.log('Промокод активирован:', response);
    } catch (error) {
      console.error('Ошибка при активации промокода:', error);
    }
  };
  </script>
<style lang="scss">
.input_group {
  margin-top: 24px;
}
main > .title {
  @include contentCenter;
  gap: 8px;
  flex-direction: column;
  div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    & > .title-text{
      font-weight: 700
    }
  }
  .subtitle {
    @include secondaryText(var(--gray-50));
    white-space: pre-line;
  }
}
</style>
