<template>
    <div class="modal-overlay" v-if="isOpen" @click.self="closeModal">
        <slot name="warning"></slot>
      <div class="modal-content">
        <slot name="header"> </slot>
        <slot name="body"> </slot>
        <slot name="footer"> </slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ModalComponent',
    props: {
      isOpen: {
        type: Boolean,
        default: false
      },
    },
    methods: {
      closeModal() {
        this.$emit('close');
      }
    }
  }
  </script>
  
  <style scoped>

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 16px;
  }
  
  .modal-content {
    background-color: var(--white);
    padding: 16px;
    border-radius: 7px;
    width: 100%;
    max-width: 375px;
  }
  
  </style>
  