<template>
  <div class="dragon">
    <span class="dragon__title">{{ dragon?.dragon?.name }}</span>
    <img :src="dragon?.dragon?.baseDragon?.image_url" alt="" />
    <span class="dragon__left_live">Осталось жить</span>
    <h1>{{ dragon?.die_at }}</h1>
    <div class="progress_bar"></div>
  </div>
</template>

<script lang="ts" setup>
import { defineOptions, defineProps, ref } from 'vue';
import { getMyDragon } from '@/api/dragon';
import type { Dragon } from '@/api/dragon';

defineOptions({
  name: 'DragonComponent',
});

// Обернуть в асинхронную функцию
async function fetchDragon() {
  const dragonPromise = getMyDragon();
  const dragon = await dragonPromise as { dragon: Dragon & { die_at?: string } };
  return dragon;
}

const dragon = ref<{ 
  id: number; 
  name: string; 
  image_url: string; 
  price: number; 
  earn_per_hour: number; 
  die_at?: string; 
  baseDragon: { 
    id: string; 
    image_url: string; 
    earn_per_hour: number; 
    price: number; 
    is_deleted: boolean; 
  } 
} | null>(null);

async function init() {
  const fetchedDragon = await fetchDragon(); // Получаем дракона
  if (fetchedDragon && fetchedDragon.dragon) {
    dragon.value = fetchedDragon.dragon; // Устанавливаем значение дракона
  } else {
    dragon.value = null; // Обработка случая, если дракон не найден
  }
}

init(); // Инициализация

</script>

<style lang="scss">
.dragon {
  @include contentCenter;
  flex-direction: column;
  .dragon__title {
    @include body(var(--gray-5));
  }
  img {
    max-height: 220px;
    margin-bottom: 16px;
  }
  .dragon__left_live {
    @include secondaryText(var(--secondary-50));
  }
  h1 {
    @include h1(var(--primary-100));
    margin-top: 4px;
    margin-bottom: 8px;
  }
  .progress_bar {
    width: 71px;
    height: 3px;
    border-radius: 40px;
    background-color: var(--orange-50);
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 40px;
      background-color: var(--orange-100);
      width: 46px;
      height: 3px;
    }
  }
}
</style>
