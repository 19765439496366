<template>
  <div class="input_group">
    <div class="subtitle">{{ title }}</div>
    <input
      :type="type"
      :value="modelValue"
      @input="updateValue"
      @blur="validateInput"
      :placeholder="placeholder"
    />
    <div class="error" v-if="error">{{ errorMessage }}</div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from 'vue';

const props = defineProps({
  modelValue: String,
  title: { type: String, default: 'Ваш кошелек' },
  placeholder: { type: String, default: '100-2000' },
  type: { type: String, default: 'text' },
  min: { type: Number, default: 0 },
  max: { type: Number, default: 1000000000 },
});

const emit = defineEmits(['update:modelValue']);

const error = ref(false);
const errorMessage = ref('');

const updateValue = (event: Event) => {
  const target = event.target as HTMLInputElement;
  emit('update:modelValue', target.value);
  validateInput();
};

const validateInput = () => {
  error.value = false;
  errorMessage.value = '';

  if (!props.modelValue?.length) {
    error.value = true;
    errorMessage.value = 'Введите корректное значение';
    return;
  }

  if (props.type === 'number') {
    const numValue = Number(props.modelValue);
    if (isNaN(numValue)) {
      error.value = true;
      errorMessage.value = 'Введите числовое значение';
    } else if (numValue < props.min) {
      error.value = true;
      errorMessage.value = `Минимальное значение: ${props.min}`;
    } else if (numValue > props.max) {
      error.value = true;
      errorMessage.value = `Максимальное значение: ${props.max}`;
    }
  }
};
</script>

<style lang="scss">
.input_group {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .subtitle {
    @include smallText2(var(--gray-50));
  }
  input {
    @include body(var(--secondary-50));
    border-radius: 100px;
    background-color: var(--primary-10);
    padding: 16px;
    border: 1px solid var(--gray-20);
    width: 100%;
    font-family: 'Comfortaa Regular', sans-serif;
    outline: none;
    &::placeholder {
      @include body(var(--secondary-50));
      font-family: 'Comfortaa Regular', sans-serif;
    }
  }
  .error {
    @include smallText2(#ff2b0f);
  }
}
</style>
