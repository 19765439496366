<template>
  <div class="dragons">
    <span class="title">{{ title }}</span>
    <span class="subtitle">{{ subtitle }}</span>
    <div class="dragons_list free" v-if="type === 'free'">
      <div class="dragon" v-for="dragon in dragons.filter(d => d.price === 0 && type === 'free')" :key="dragon.id">
        <img :src="dragon.image_url" alt="">
        <div class="dragon__info">
          <div class="rate_list">
            <ActiveCoinSvg class="rate" v-for="n in Math.min(dragon.earn_per_hour, 5)" :key="`active-${n}`" />
            <NonActiveCoinSvg class="rate" v-for="n in Math.max(0, 5 - dragon.earn_per_hour)" :key="`inactive-${n}`" />
          </div>
          <button @click="selectDragon(dragon.id)">Выбрать</button>
        </div>
      </div>
      <div class="dragon" v-for="dragon in dragons.filter(d => d.price > 0 && type === 'paid')" :key="dragon.id">
        <img :src="dragon.image_url" alt="">
        <div class="dragon__info">
          <div class="rate_list">
            <ActiveCoinSvg class="rate" v-for="n in Math.min(dragon.earn_per_hour, 5)" :key="`active-${n}`" />
            <NonActiveCoinSvg class="rate" v-for="n in Math.max(0, 5 - dragon.earn_per_hour)" :key="`inactive-${n}`" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, defineProps, defineEmits, defineOptions } from 'vue';
import ActiveCoinSvg from './Icons/ActiveCoinSvg.vue';
import NonActiveCoinSvg from './Icons/NonActiveCoinSvg.vue';
import { getAllBaseDragons, createFreeDragon, Dragon } from '@/api/dragon';
import { useRouter } from 'vue-router';
import router from '@/router';

const dragonName = ref('');

// Add emits declaration
const emit = defineEmits(['update:dragonName']);

defineOptions({
  name: 'DragonsComponent'
});

//TEST DATA
/*
const dragons = [
  {
    id: 1,
    image_url: 'https://i.ibb.co/3SmGp3n/dragon-1.png',
    price: 0,
    earn_per_hour: 3,
  },
  {
    id: 2,
    image_url: 'https://i.ibb.co/3SmGp3n/dragon-1.png',
    price: 0,
    earn_per_hour: 2,
  },
  {
    id: 3,
    image_url: 'https://i.ibb.co/3SmGp3n/dragon-1.png',
    price: 0,
    earn_per_hour: 1,
  }
]*/

const dragons = await getAllBaseDragons();

const selectDragon = async (id: number) => {
  console.log(id);

  emit('update:dragonName', dragonName.value);
  console.log(dragonName.value);
  try {
    const res = await createFreeDragon({
      base_dragon_id: id,
      name: 'Бесплатный дракон',
    });
    console.log(res);
    router.push('/');
  } catch (error) {
    console.error('Error creating dragon:', error);
  }
}

// Add dragonName to props
defineProps<{
  title: string;
  subtitle: string;
  type: 'free' | 'paid';
  dragonName?: string;
  
}>();
</script>

<style lang="scss">
.dragons {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    color: var(--primary-100);
    text-align: left;
    font-size: 14px;
  }

  .dragons_list {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    .dragon {
      display: flex;
      flex-direction: column;
      gap: 8px;

      img {
        width: 100%;
      }

      .dragon__info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        flex-direction: column;

        button {
          width: 100%;
          padding: 10px 12px;
          border-radius: 8px;
          background-color: transparent;
          border: 2px solid var(--primary-100);
          cursor: pointer;
          font-family: "Comfortaa Regular", sans-serif !important;
          @include body(var(--primary-100));
          transition: all 0.3s ease;

          &:hover {
            background-color: var(--primary-100);
            color: var(--white);
          }
        }
      }
    }
  }

  .subtitle {
    color: var(--gray-50);
    font-size: 13px;
    line-height: 19.5px;
    font-weight: 400;
  }
}
</style>
