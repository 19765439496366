<template>
  <HeaderComponent :theme="theme" />
  <main>
    <MoneyComponent 
        :earn_per_hour="user?.dragons?.find(dragon => dragon.active)?.baseDragon.earn_per_hour ?? 0" 
        :balance="user?.balance ?? 0" 
    />
    <DragonComponent/>
    <MenuComponent/>
    <CardComponent/>
  </main>
  <FooterComponent/>

  <BackgroundComponent />
</template>

<script setup lang="ts">
import { ref } from "vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import MoneyComponent from "@/components/MoneyComponent.vue";
import BackgroundComponent from "@/components/Icons/BackgroundComponent.vue";
import DragonComponent from "@/components/DragonComponent.vue";
import MenuComponent from "@/components/MenuComponent.vue";
import CardComponent from "@/components/CardComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import { getMe } from "@/api/user";
import type { User } from "@/api/user"; // Импортируйте тип User

const theme = ref<"light" | "dark">("light"); // Debugging by setting initial value to 'light'

const user = ref<User | null>(null); // Уточнён тип для user

async function fetchUser() {
    user.value = await getMe(); // Обернуто в асинхронную функцию
}

fetchUser(); // Вызов функции для получения пользователя

</script>

<style lang="scss">
</style>
