<template>
  <div class="otzivi">
    <div class="otzivi_container">
      <div class="otzivi_up">
        <div class="otzivi_up_header">
          <img src="@/assets/img/otzivi_up_icon.svg" alt="" class="otzivi_up_logo">
          <div class="otzivi_up_texts">
            <p class="otzivi_up_texts_tittle">Ocenka.ru</p>
            <p class="otzivi_up_texts_text">Отзывы пользователей</p>
          </div>
          <input type="text" placeholder="Поиск" class="otzivi_up_search">
        </div>
        <div class="otzivi_up_info">
          <img src="@/assets/img/otzivi_info_image.png" alt="" class="otzivi_up_info_image">
          <div class="otzivi_up_info_raiting">
            <h1 class="otzivi_up_info_tittle">NFT Dragon</h1>
            <div class="otzivi_up_info_raiting_middle">Средняя оцена <span>4.87</span></div>
            <p class="otzivi_up_info_raiting_votes">4603 голосов</p>
            <div class="otzivi_up_info_raiting_stars">
              <img src="@/assets/img/otzivi_star.png" alt="" class="otzivi_up_info_raiting_star">
              <img src="@/assets/img/otzivi_star.png" alt="" class="otzivi_up_info_raiting_star">
              <img src="@/assets/img/otzivi_star.png" alt="" class="otzivi_up_info_raiting_star">
              <img src="@/assets/img/otzivi_star.png" alt="" class="otzivi_up_info_raiting_star">
              <img src="@/assets/img/otzivi_star05.svg" alt="" class="otzivi_up_info_raiting_star">
            </div>
          </div>
        </div>
        <button class="otzivi_up_button">Перейти на сайт <img src="@/assets/img/otzivi_button_arow.svg" alt=""></button>
      </div>
    </div>
    <div class="otzivi_main">
      <p class="otzivi_main_tittle">Главная страница сайта:</p>
      <img src="@/assets/img/otzivi_main_image.png" alt="" class="otzivi_main_image">
      <div class="otzivi_main_texts">
        <p class="otzivi_main_texts_tittle">Dragons-NFT игра 2023 года: отзывы и обзор проекта.</p>
        <div class="otzivi_main_texts_items">
          <p class="otzivi_main_texts_item">"Dragons" представляет собой онлайн NFT-игру, разработанную теми же программистами, что стояли в истоках другой успешной NFT-игры "IguVerse". Платформа функционирует на основе собственной криптовалюты под названием "Dragons COIN".</p>
          <p class="otzivi_main_texts_item">На текущий момент, проект "Dragons" доступен в формате веб-сайта и Телеграм-бота, применяемого для авторизации пользователей и реализации эйрдропов. С момента запуска в начале 2023 года, создатели уверенно заявили, что их NFT-игра послужит фундаментом для криптовалюты Dragons COIN, позволяя привлекать в проект крупные инвестиции и укрепляя экономический цикл, в результате которого игроки получают свои выплаты.</p>
          <p class="otzivi_main_texts_item">Что касается возможностей для дохода, то здесь не установлено никаких ограничений. Потенциал для заработка и вывода средств зависит исключительно от времени, уделенного игре. В приложении предусмотрен пассивный доход, начисляемый каждый час - без покупки дополнительных ускорителей он составляет около 40 рублей в час. Основной доход можно получать, участвуя в играх на сайте. Наш опыт показывает, что в первые два часа игры можно заработать до 1500 рублей, после чего доходность немного снижается в течение остатка дня до его обновления.</p>
          <p class="otzivi_main_texts_item">Важным аспектом, на который создатели игры акцентируют внимание, является продолжительность жизни вашего питомца. Этот показатель может быть продлен за счет "кликов" по питомцу. Максимальная продолжительность жизни составляет 24 часа. Если не успеть продлить жизнь питомца в этот срок, он, погибает. Но даже в этом случае вы не теряете свои заработанные деньги, просто прекращается поступление пассивного дохода от питомца. Если зайти в игру снова и восстановить питомца, доход возобновится. Это условие создано для отсеивания неактивных игроков, которые могут накопить средства на своем балансе без активного участия в игре.</p>
        </div>
      </div>
    </div>
    <div class="otzivi_bottom">
      <div class="otzivi_bottom_forms">
        <p class="otzivi_bottom_forms_tittle">Напишите свой отзыв</p>
        <input type="text" placeholder="Ваше имя" class="otzivi_bottom_forms_name">
        <textarea class="otzivi_bottom_forms_text" placeholder="Ваш отзыв" rows="5" name="" id=""></textarea>
        <button class="otzivi_bottom_forms_button">Отправить отзыв</button>
      </div>
    </div>
    <div class="otzivi_bottom_otzivi">
      <p class="otzivi_bottom_otzivi_tittle">Все отзывы:</p>
      <div class="otzivi_bottom_otzivi_items">
        <div class="otzivi_bottom_otzivi_item">
          <div class="otzivi_bottom_otzivi_item_content">
            <div class="otzivi_bottom_otzivi_item_up">
              <div class="otzivi_bottom_otzivi_item_up_name">Дарина</div>
              <div class="otzivi_bottom_otzivi_item_up_date">15.05.24 в 19:14</div>
            </div>
            <p class="otzivi_bottom_otzivi_item_text">Ребят, это не их комиссия, а комиссия платежной системы. Не надо писать плохой отзыв только из за комиссии на оплате, сначала сделайте вывод, он спокойно покроет любые комиссии.</p>
          </div>
        </div>
        <div class="otzivi_bottom_otzivi_item">
          <div class="otzivi_bottom_otzivi_item_content">
            <div class="otzivi_bottom_otzivi_item_up">
              <div class="otzivi_bottom_otzivi_item_up_name">Макс</div>
              <div class="otzivi_bottom_otzivi_item_up_date">15.05.24 в 19:14</div>
            </div>
            <p class="otzivi_bottom_otzivi_item_text">Жалею только о том, что не сразу поверил рекламе. Прошла неделя пока я наконец то решил зайти и проверить. На удивление, они и правда выводят!</p>
          </div>
        </div>
        <div class="otzivi_bottom_otzivi_item">
          <div class="otzivi_bottom_otzivi_item_content">
            <div class="otzivi_bottom_otzivi_item_up">
              <div class="otzivi_bottom_otzivi_item_up_name">Петр</div>
              <div class="otzivi_bottom_otzivi_item_up_date">14.05.24 в 13:12</div>
            </div>
            <p class="otzivi_bottom_otzivi_item_text">Знаю что это отзывы, но хочу дать совет новичкам: покупайте бустеры сразу, они очень сильно увеличивают доход, а окупаются в этот же день</p>
          </div>
        </div>
        <div class="otzivi_bottom_otzivi_item">
          <div class="otzivi_bottom_otzivi_item_content">
            <div class="otzivi_bottom_otzivi_item_up">
              <div class="otzivi_bottom_otzivi_item_up_name">Юрий</div>
              <div class="otzivi_bottom_otzivi_item_up_date">14.05.24 в 11:14</div>
            </div>
            <p class="otzivi_bottom_otzivi_item_text">Пять звезд и точка :3</p>
          </div>
        </div>
        <div class="otzivi_bottom_otzivi_item">
          <div class="otzivi_bottom_otzivi_item_content">
            <div class="otzivi_bottom_otzivi_item_up">
              <div class="otzivi_bottom_otzivi_item_up_name">Вика</div>
              <div class="otzivi_bottom_otzivi_item_up_date">13.05.24 в 15:14</div>
            </div>
            <p class="otzivi_bottom_otzivi_item_text">Выводы работают, все круто!</p>
          </div>
        </div>
        <div class="otzivi_bottom_otzivi_item">
          <div class="otzivi_bottom_otzivi_item_content">
            <div class="otzivi_bottom_otzivi_item_up">
              <div class="otzivi_bottom_otzivi_item_up_name">Фёдор</div>
              <div class="otzivi_bottom_otzivi_item_up_date">13.05.24 в 09:14</div>
            </div>
            <p class="otzivi_bottom_otzivi_item_text">Делал вывод на Сбербанк, все дошло за пару минут, однозначно 10/10.</p>
          </div>
        </div>
        <div class="otzivi_bottom_otzivi_item">
          <div class="otzivi_bottom_otzivi_item_content">
            <div class="otzivi_bottom_otzivi_item_up">
              <div class="otzivi_bottom_otzivi_item_up_name">Олег</div>
              <div class="otzivi_bottom_otzivi_item_up_date">13.05.24 в 07:04</div>
            </div>
            <p class="otzivi_bottom_otzivi_item_text">хочу дожить до того момента когда вашим амбасадором станет какое то крупное медийное лицо, а я буду знать что начал зарабатывать здесь еще до всей этой движухи</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OtziviView',
  data() {
    return {
      // your data here
    }
  },
  methods: {
    // your methods here
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #f2f2f2;
  display: block;
}

body {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  top: 0;
  left: 0;
  padding: 0;
  background: #fff;
  margin: 0;
  width: 100%;
  height: 100%;
}

html,
body {
  width: 100vw;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #c8d5de;
  height: 5px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #000;
  width: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #c8d5de;
}














.payment {
  width: 100%;
  height: 100%;
  background: url(../assets/img/bg.jpg)top no-repeat;
  background-size: auto;
}
.payment_form {
  padding: 60px 16px;
  padding-bottom: 51px;
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 0 auto;
}
.payment_form_rect {
  width: 100%;
  height: auto;
  border-radius: 16px;
border: 1px solid #ECECEC;
background: #F4F4F9;
}
.payment_form_rect_content {
  padding: 16px;
}
.payment_form_rect_up {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #E0E4E6;
}
.payment_form_rect_up_summa {
  display: flex;
  flex-direction: column;
}
.payment_form_rect_up_summa_tittle {
  color: #9B9C9D;
font-family: "Inter", sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.payment_form_rect_up_summa_text {
  margin-top: 8px;
  color: #000;
font-family: "Inter", sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;

}
.payment_form_rect_up_icon {
  vertical-align: middle;
  margin-left: auto;
}
.payment_form_rect_inputs {
}
.payment_form_rect_up_block {
  margin-top: 16px;
  width: 100%;
}
.payment_form_rect_up_block_tittle {
  color: #9B9C9D;
font-family: "Inter", sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.payment_form_rect_up_block_input {
  width: 100%;
  height: auto;
  color: #1E1E1E;

font-family: "Inter", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding: 15.5px 16px;
border-radius: 8px;
border: 1px solid #E0E4E6;
background: #FFF;
margin-top: 8px;
}

.payment_form_rect_up_block_input_error{
  color: #F00;
font-family: "Inter", sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 8px;
display: none;
}
.payment_form_rect_up_block_error .payment_form_rect_up_block_input_error{
  display: block;
}
.payment_form_rect_up_block_error .payment_form_rect_up_block_input{
  border: 1px solid #F00;
}
.payment_form_rect_up_block_input:focus{
  outline: none; /* Убираем стандартную обводку */
    box-shadow: none; /* Убираем тень, если она есть */
}
.payment_form_rect_up_block_input::placeholder{
  color: #E0E4E6;
}
.payment_form_rect_up_blocks {
  display: flex;
  align-items: flex-start;
}
.payment_form_rect_up_blocks .payment_form_rect_up_block:first-child{
  margin-right: 9px;
}
.payment_form_rect_button {
  margin-top: 16px;
  width: 100%;
  height: auto;
  color: #FFF;
font-family: "Inter", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding-top: 15.5px;
padding-bottom: 15.5px;
width: 100%;
height: auto;
border-radius: 8px;
background: #A7A7A7;
border: none;
outline: none;
}
.payment_form_rect_button_active{
  background: #242424;
color: #fff;
transition: all .5s;
cursor: pointer;
}
.payment_form_rect_button_active:hover{
  transform: scale(.9);
}
.payment_form_rect_parthners {
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.payment_form_rect_parthner {
  margin-left: auto;
}
.payment_form_rect_parthner:first-child{
  margin-left: 0px;
}
.payment_form_rect_bottom_texts {
  margin-top: 32px;
}
.payment_form_rect_politic {
  color: #0C25F2;
font-family: "Inter", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
cursor: pointer;
transition: all .5s;
text-align: center;
}
.payment_form_rect_politic:hover{
  opacity: .5;
}
.payment_form_rect_bottom_help {
  margin-top: 8px;
  color: #9B9C9D;
font-family: "Inter", sans-serif;
font-size: 12px;
font-style: normal;
text-align: center;
font-weight: 400;
line-height: normal;
}
.payment_form_rect_bottom_help span{
  color: #7E8BF5;
font-family: "Inter", sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
}
.payment_latest {
  margin-top: 80px;
}
.payment_latest_tittle {
  color: #6D6D6D;
font-family: "Inter", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.payment_latest_items {
  display: flex;
  flex-direction: column;
}
.payment_latest_item {
  margin-bottom: 17px;
  display: flex;
  align-items: center;
}
.payment_latest_item:first-child{
  margin-top: 24px;
}
.payment_latest_item_avatar {
  width: 40px;
height: 40px;
border-radius: 100%;
object-fit: cover;
}
.payment_latest_item_texts {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}
.payment_latest_item_tittle {
  color: #000;
font-family: "Inter", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.payment_latest_item_time {
  margin-top: 4px;
  color: #9B9C9D;
font-family: "Inter", sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.payment_latest_item_price {
  color: #0C25F2;
font-family: "Inter", sans-serif;
margin-left: auto;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
}




















.otzivi {
  width: 100%;
  height: auto;
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 0 auto;
}
.otzivi_container {
  padding: 50px 16px;
}
.otzivi_up {

}
.otzivi_up_header {
  display: flex;
  align-items: center;
}
.otzivi_up_logo {
  vertical-align: auto;
}

.otzivi_up_texts {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
}
.otzivi_up_texts_tittle {
  color: #424A43;
font-variant-numeric: ordinal;
font-feature-settings: 'dnom' on, 'salt' on, 'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv06' on, 'cv07' on, 'cv08' on, 'cv09' on, 'cv10' on, 'cv11' on;
font-family: "Inter", sans-serif;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.otzivi_up_texts_text {
  margin-top: 2px;
  color: #424A43;
font-family: "Inter", sans-serif;
font-size: 9px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.otzivi_up_search {
  margin-left: auto;
  color: #1E1E1E;
font-family: "Inter", sans-serif;
padding: 12px 16px;
border-radius: 4px;
border: 1px solid #D5E4DC;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
background: transparent url(../assets/img/otzivi_up_serch_icon.svg)right no-repeat;
width: 100%;
padding-right: 56px;
max-width: 144px;
outline: none;
background-position-x: calc(100% - 16px);
}
.otzivi_up_search::placeholder{
  color: #ADBBB0;
}
.otzivi_up_info {
  display: flex;
  align-items: center;
  margin-top: 40px;
  justify-content: center;
}
.otzivi_up_info_image {
  vertical-align: middle;
  width: 100%;
  max-width: 148px;
}
.otzivi_up_info_tittle {
  color: #424A43;
font-family: "Inter", sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.otzivi_up_info_raiting {
margin-left: 40px;
}
.otzivi_up_info_raiting_middle {
  color: #828282;
  font-family: "Inter", sans-serif;
  margin-top: 27px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.otzivi_up_info_raiting_middle span{
  color: #424A43;
  font-weight: 700;
}
.otzivi_up_info_raiting_votes {
  margin-top: 8px;
  color: #424A43;
font-family: "Inter", sans-serif;
font-size: 14px;
font-style: normal;
text-align: center;
font-weight: 600;
line-height: normal;
}
.otzivi_up_info_raiting_stars {
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.otzivi_up_info_raiting_star {
  vertical-align: middle;
  margin-right: 4px;
}
.otzivi_up_info_raiting_star:last-child{
  margin-right: 0px;
}
.otzivi_up_button {
  margin-top: 16px;
  width: 100%;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all .5s;
  display: flex;
  align-items: center;
  color: #FFF;
justify-content: center;
  font-family: "Inter", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding-top: 12px;
padding-bottom: 12px;
border-radius: 8px;
background: #2EB934;
}
.otzivi_up_button img{
  vertical-align: middle;
  margin-left: 8px;
}
.otzivi_up_button:hover{
  transform: scale(.9);
}
.otzivi_main {
  margin-top: 40px;
}
.otzivi_main_tittle {
  color: #828282;
font-family: "Inter", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.otzivi_main_image {
  display: block;
  margin: 0 auto;
  vertical-align: middle;
  margin-top: 16px;
}
.otzivi_main_texts {
  margin-top: 40px;
}
.otzivi_main_texts_tittle {
  color: #000;
font-family: "Inter", sans-serif;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: 140%; /* 23.8px */
}
.otzivi_main_texts_items {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}
.otzivi_main_texts_item {
  color: #000;
font-family: "Inter", sans-serif;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 19.5px */
margin-bottom: 15px;
}
.otzivi_main_texts_item:last-child{
  margin-bottom: 0px;
}
.otzivi_bottom {
  margin-top: 40px;
}
.otzivi_bottom_forms {
}
.otzivi_bottom_forms_tittle {
  color: #000;
font-family: "Inter", sans-serif;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: 140%; /* 23.8px */
}
.otzivi_bottom_forms_name {
  margin-top: 24px;
  width: 100%;
  max-width: 163px;
  outline: none;
  color: #1E1E1E;
font-family: "Inter", sans-serif;
padding: 12px 16px;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
border-radius: 4px;
border: 1px solid #D5E4DC;
}
.otzivi_bottom_forms_name::placeholder{
  color: #ADBBB0;
}
.otzivi_bottom_forms_text {
  margin-top: 16px;
  border-radius: 4px;
  border: 1px solid #D5E4DC;
  color: #1E1E1E;
font-family: "Inter", sans-serif;
outline: none;
font-size: 12px;
width: 100%;
font-style: normal;
padding: 16px;
font-weight: 400;
line-height: normal;
}
.otzivi_bottom_forms_text::placeholder{
  color: #ADBBB0;
}
.otzivi_bottom_forms_button {
  width: 100%;
  height: auto;
  outline: none;
  cursor: pointer;
  transition: all .5s;
  margin-top: 16px;
  color: #FFF;
font-family: "Inter", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding-top: 11.5px;
padding-bottom: 11.5px;
border-radius: 8px;
background: #2EB934;
border: none;
}
.otzivi_bottom_forms_button:hover{
  transform: scale(.9);
}
.otzivi_bottom_otzivi {
  margin-top: 40px;
}
.otzivi_bottom_otzivi_tittle {
  color: #000;
font-family: "Inter", sans-serif;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: 140%; /* 23.8px */
}
.otzivi_bottom_otzivi_items {
  display: flex;
  flex-direction: column;
}
.otzivi_bottom_otzivi_item {
  margin-bottom: 16px;
  width: 100%;
  height: auto;
  border-radius: 8px;
border: 1px solid #F7F7F7;
background: #FFF;
box-shadow: 0px 0px 7px 0px rgba(104, 107, 103, 0.07);
}
.otzivi_bottom_otzivi_item:first-child{
  margin-top: 24px;
}
.otzivi_bottom_otzivi_item:last-child{
  margin-bottom: 0px;
}
.otzivi_bottom_otzivi_item_content {
  padding: 16px;
}
.otzivi_bottom_otzivi_item_up {
  display: flex;
  align-items: center;
}
.otzivi_bottom_otzivi_item_up_name {
  color: #000;
font-family: "Inter", sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.otzivi_bottom_otzivi_item_up_date {
  margin-left: auto;
  color: #C7C7C7;
font-family: "Inter", sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 18px */
}
.otzivi_bottom_otzivi_item_text {
  margin-top: 16px;
  color: #535353;
font-family: "Inter", sans-serif;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 18px */
}


@media (min-width:320px) and (max-width:374px){
  .otzivi_up_info_raiting {
    margin-left: 20px;
}
}
</style>
