<template>
  <div class="form">
    
  </div>
</template>

<style scoped lang="scss">
body {
  background: #000;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('@/assets/img/circles.png') no-repeat center center / cover;
}
</style>

<script setup lang="ts">

</script>