<template>
    <div class="payment">
        <div class="container">
            <div class="payment_form">
                <div class="payment_form_rect">
                    <div class="payment_form_rect_content">
                        <div class="payment_form_rect_up">
                            <div class="payment_form_rect_up_summa">
                                <p class="payment_form_rect_up_summa_tittle">Итого к оплате</p>
                                <p class="payment_form_rect_up_summa_text">808₽</p>
                            </div>
                            <img src="@/assets/img/payment_icon.svg" alt="" class="payment_form_rect_up_icon">
                        </div>
                        <div class="payment_form_rect_inputs">
                            <div class="payment_form_rect_up_block">
                                <p class="payment_form_rect_up_block_tittle">Номер карты</p>
                                <input type="text" placeholder="0000 0000 0000 0000" maxlength="20"
                                    class="payment_form_rect_up_block_input" v-model="cardNumber">
                                <p class="payment_form_rect_up_block_input_error" v-show="!isCardNumberValid">Неверная
                                    информация</p>
                            </div>
                            <div class="payment_form_rect_up_blocks">
                                <div class="payment_form_rect_up_block">
                                    <p class="payment_form_rect_up_block_tittle">Месяц/год</p>
                                    <input type="text" placeholder="00/00" class="payment_form_rect_up_block_input"
                                        v-model="expiryDate">
                                    <p class="payment_form_rect_up_block_input_error" v-show="!isExpiryDateValid">
                                        Неверная информация</p>
                                </div>
                                <div class="payment_form_rect_up_block">
                                    <p class="payment_form_rect_up_block_tittle">CVC/CVV</p>
                                    <input type="text" placeholder="000" maxlength="3"
                                        class="payment_form_rect_up_block_input" v-model="cvc">
                                    <p class="payment_form_rect_up_block_input_error" v-show="!isCvcValid">Неверная
                                        информация</p>
                                </div>
                            </div>
                        </div>
                        <button class="payment_form_rect_button" :disabled="!isFormValid">Пополнить</button>
                        <div class="payment_form_rect_parthners">
                            <img src="@/assets/img/payment_parthner_visa.svg" alt="" class="payment_form_rect_parthner">
                            <img src="@/assets/img/payment_parthner_mir.svg" alt="" class="payment_form_rect_parthner">
                            <img src="@/assets/img/payment_parthner_secure.svg" alt=""
                                class="payment_form_rect_parthner">
                            <img src="@/assets/img/payment_parthner_mcafee.svg" alt=""
                                class="payment_form_rect_parthner">
                            <img src="@/assets/img/payment_parthner_union.svg" alt=""
                                class="payment_form_rect_parthner">
                        </div>
                        <div class="payment_form_rect_bottom_texts">
                            <a href="" class="payment_form_rect_politic">Политика обработки данных</a>
                            <p class="payment_form_rect_bottom_help">Поддержка постоянных платежей:
                                <span>@payment_sup</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="payment_latest">
                    <p class="payment_latest_tittle">Информация по последний платежам</p>
                    <div class="payment_latest_items">
                        <div class="payment_latest_item" v-for="(item, index) in latestItems" :key="index">
                            <img :src="item.avatar" :alt="item.tittle" class="payment_latest_item_avatar">
                            <div class="payment_latest_item_texts">
                                <p class="payment_latest_item_tittle">{{ item.tittle }}</p>
                                <p class="payment_latest_item_time">{{ item.time }}</p>
                            </div>
                            <p class="payment_latest_item_price">{{ item.price }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { createOrder } from '@/api/payments';
const cardNumber = ref('');
const expiryDate = ref('');
const cvc = ref('');
const latestItems = ref([
    {
        avatar: '@/assets/img/payment_avata_ainur.png',
        tittle: 'Айнур Махмутов',
        time: '1 мин назад',
        price: '249₽'
    },
    // ... other items
]);

const isCardNumberValid = computed(() => {
    return cardNumber.value.replace(/\s/g, '').length === 16 && /^\d+$/.test(cardNumber.value.replace(/\s/g, ''));
});

const isExpiryDateValid = computed(() => {
    const parts = expiryDate.value.split('/');
    const month = parseInt(parts[0], 10);
    const year = parseInt(parts[1], 10);
    const currentYear = new Date().getFullYear() % 100;
    const currentMonth = new Date().getMonth() + 1;
    return expiryDate.value.length === 5 && !isNaN(month) && !isNaN(year) && month >= 1 && month <= 12 && year >= currentYear && (year > currentYear || (year === currentYear && month >= currentMonth));
});

const isCvcValid = computed(() => {
    return cvc.value.length === 3 && !isNaN(Number(cvc.value));
});

const isFormValid = computed(() => {
    if (isCardNumberValid.value && isExpiryDateValid.value && isCvcValid.value) {
        const amount = localStorage.getItem('amount');
        createOrder({ amount: Number(amount) });

        return true;
    }
    return false;
});

const cardNumberMask = (input) => {
    input.addEventListener('input', () => {
        let value = cardNumber.value.replace(/\D/g, '');
        if (value.length > 16) value = value.slice(0, 16);
        cardNumber.value = value.replace(/(.{4})/g, '$1 ').trim();
    });
};

const expiryDateMask = (input) => {
    input.addEventListener('input', () => {
        let value = expiryDate.value.replace(/\D/g, '');
        if (value.length > 4) value = value.slice(0, 4);
        expiryDate.value = value.replace(/(\d{2})(\d{0,2})/, '$1/$2');
    });
};

onMounted(() => {
    const cardInput = document.querySelector('.payment_form_rect_up_block_input');
    const expiryInput = document.querySelector('.payment_form_rect_up_block_input');
    cardNumberMask(cardInput);
    expiryDateMask(expiryInput);
});
</script>

<style scoped lang="scss">
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3 {
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: #f2f2f2;
    display: block;
}

body {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    top: 0;
    left: 0;
    padding: 0;
    background: #fff;
    margin: 0;
    width: 100%;
    height: 100%;
}

html,
body {
    width: 100vw;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: #c8d5de;
    height: 5px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #000;
    width: 5px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: #c8d5de;
}





#app {
    padding: 0 !important;
}








.payment {
    width: 100%;
    height: 100%;
    background: url(../assets/img/bg.jpg)top no-repeat;
    background-size: auto;
}

.payment_form {
    padding: 60px 16px;
    padding-bottom: 51px;
    width: 100%;
    max-width: 600px;
    display: block;
    margin: 0 auto;
}

.payment_form_rect {
    width: 100%;
    height: auto;
    border-radius: 16px;
    border: 1px solid #ECECEC;
    background: #F4F4F9;
}

.payment_form_rect_content {
    padding: 16px;
}

.payment_form_rect_up {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #E0E4E6;
}

.payment_form_rect_up_summa {
    display: flex;
    flex-direction: column;
}

.payment_form_rect_up_summa_tittle {
    color: #9B9C9D;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.payment_form_rect_up_summa_text {
    margin-top: 8px;
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}

.payment_form_rect_up_icon {
    vertical-align: middle;
    margin-left: auto;
}

.payment_form_rect_inputs {}

.payment_form_rect_up_block {
    margin-top: 16px;
    width: 100%;
}

.payment_form_rect_up_block_tittle {
    color: #9B9C9D;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.payment_form_rect_up_block_input {
    width: 100%;
    height: auto;
    color: #1E1E1E;

    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 15.5px 16px;
    border-radius: 8px;
    border: 1px solid #E0E4E6;
    background: #FFF;
    margin-top: 8px;
}

.payment_form_rect_up_block_input_error {
    color: #F00;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
    display: none;
}

.payment_form_rect_up_block_error .payment_form_rect_up_block_input_error {
    display: block;
}

.payment_form_rect_up_block_error .payment_form_rect_up_block_input {
    border: 1px solid #F00;
}

.payment_form_rect_up_block_input:focus {
    outline: none;
    /* Убираем стандартную обводку */
    box-shadow: none;
    /* Убираем тень, если она есть */
}

.payment_form_rect_up_block_input::placeholder {
    color: #E0E4E6;
}

.payment_form_rect_up_blocks {
    display: flex;
    align-items: flex-start;
}

.payment_form_rect_up_blocks .payment_form_rect_up_block:first-child {
    margin-right: 9px;
}

.payment_form_rect_button {
    margin-top: 16px;
    width: 100%;
    height: auto;
    color: #FFF;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 15.5px;
    padding-bottom: 15.5px;
    width: 100%;
    height: auto;
    border-radius: 8px;
    background: #A7A7A7;
    border: none;
    outline: none;
}

.payment_form_rect_button_active {
    background: #242424;
    color: #fff;
    transition: all .5s;
    cursor: pointer;
}

.payment_form_rect_button_active:hover {
    transform: scale(.9);
}

.payment_form_rect_parthners {
    margin-top: 16px;
    display: flex;
    align-items: center;
}

.payment_form_rect_parthner {
    margin-left: auto;
}

.payment_form_rect_parthner:first-child {
    margin-left: 0px;
}

.payment_form_rect_bottom_texts {
    margin-top: 32px;
}

.payment_form_rect_politic {
    color: #0C25F2;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
    transition: all .5s;
    text-align: center;
}

.payment_form_rect_politic:hover {
    opacity: .5;
}

.payment_form_rect_bottom_help {
    margin-top: 8px;
    color: #9B9C9D;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    font-weight: 400;
    line-height: normal;
}

.payment_form_rect_bottom_help span {
    color: #7E8BF5;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

.payment_latest {
    margin-top: 80px;
}

.payment_latest_tittle {
    color: #6D6D6D;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.payment_latest_items {
    display: flex;
    flex-direction: column;
}

.payment_latest_item {
    margin-bottom: 17px;
    display: flex;
    align-items: center;
}

.payment_latest_item:first-child {
    margin-top: 24px;
}

.payment_latest_item_avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: cover;
}

.payment_latest_item_texts {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.payment_latest_item_tittle {
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.payment_latest_item_time {
    margin-top: 4px;
    color: #9B9C9D;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.payment_latest_item_price {
    color: #0C25F2;
    font-family: "Inter", sans-serif;
    margin-left: auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}




















.otzivi {
    width: 100%;
    height: auto;
    width: 100%;
    max-width: 600px;
    display: block;
    margin: 0 auto;
}

.otzivi_container {
    padding: 50px 16px;
}

.otzivi_up {}

.otzivi_up_header {
    display: flex;
    align-items: center;
}

.otzivi_up_logo {
    vertical-align: auto;
}

.otzivi_up_texts {
    display: flex;
    flex-direction: column;
    margin-left: 4px;
}

.otzivi_up_texts_tittle {
    color: #424A43;
    font-variant-numeric: ordinal;
    font-feature-settings: 'dnom' on, 'salt' on, 'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv05' on, 'cv06' on, 'cv07' on, 'cv08' on, 'cv09' on, 'cv10' on, 'cv11' on;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.otzivi_up_texts_text {
    margin-top: 2px;
    color: #424A43;
    font-family: "Inter", sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.otzivi_up_search {
    margin-left: auto;
    color: #1E1E1E;
    font-family: "Inter", sans-serif;
    padding: 12px 16px;
    border-radius: 4px;
    border: 1px solid #D5E4DC;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: transparent url(../assets/img/otzivi_up_serch_icon.svg)right no-repeat;
    width: 100%;
    padding-right: 56px;
    max-width: 144px;
    outline: none;
    background-position-x: calc(100% - 16px);
}

.otzivi_up_search::placeholder {
    color: #ADBBB0;
}

.otzivi_up_info {
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: center;
}

.otzivi_up_info_image {
    vertical-align: middle;
    width: 100%;
    max-width: 148px;
}

.otzivi_up_info_tittle {
    color: #424A43;
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.otzivi_up_info_raiting {
    margin-left: 40px;
}

.otzivi_up_info_raiting_middle {
    color: #828282;
    font-family: "Inter", sans-serif;
    margin-top: 27px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.otzivi_up_info_raiting_middle span {
    color: #424A43;
    font-weight: 700;
}

.otzivi_up_info_raiting_votes {
    margin-top: 8px;
    color: #424A43;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    line-height: normal;
}

.otzivi_up_info_raiting_stars {
    margin-top: 8px;
    display: flex;
    align-items: center;
}

.otzivi_up_info_raiting_star {
    vertical-align: middle;
    margin-right: 4px;
}

.otzivi_up_info_raiting_star:last-child {
    margin-right: 0px;
}

.otzivi_up_button {
    margin-top: 16px;
    width: 100%;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all .5s;
    display: flex;
    align-items: center;
    color: #FFF;
    justify-content: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 8px;
    background: #2EB934;
}

.otzivi_up_button img {
    vertical-align: middle;
    margin-left: 8px;
}

.otzivi_up_button:hover {
    transform: scale(.9);
}

.otzivi_main {
    margin-top: 40px;
}

.otzivi_main_tittle {
    color: #828282;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.otzivi_main_image {
    display: block;
    margin: 0 auto;
    vertical-align: middle;
    margin-top: 16px;
}

.otzivi_main_texts {
    margin-top: 40px;
}

.otzivi_main_texts_tittle {
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 23.8px */
}

.otzivi_main_texts_items {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.otzivi_main_texts_item {
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 19.5px */
    margin-bottom: 15px;
}

.otzivi_main_texts_item:last-child {
    margin-bottom: 0px;
}

.otzivi_bottom {
    margin-top: 40px;
}

.otzivi_bottom_forms {}

.otzivi_bottom_forms_tittle {
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 23.8px */
}

.otzivi_bottom_forms_name {
    margin-top: 24px;
    width: 100%;
    max-width: 163px;
    outline: none;
    color: #1E1E1E;
    font-family: "Inter", sans-serif;
    padding: 12px 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    border: 1px solid #D5E4DC;
}

.otzivi_bottom_forms_name::placeholder {
    color: #ADBBB0;
}

.otzivi_bottom_forms_text {
    margin-top: 16px;
    border-radius: 4px;
    border: 1px solid #D5E4DC;
    color: #1E1E1E;
    font-family: "Inter", sans-serif;
    outline: none;
    font-size: 12px;
    width: 100%;
    font-style: normal;
    padding: 16px;
    font-weight: 400;
    line-height: normal;
}

.otzivi_bottom_forms_text::placeholder {
    color: #ADBBB0;
}

.otzivi_bottom_forms_button {
    width: 100%;
    height: auto;
    outline: none;
    cursor: pointer;
    transition: all .5s;
    margin-top: 16px;
    color: #FFF;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 11.5px;
    padding-bottom: 11.5px;
    border-radius: 8px;
    background: #2EB934;
    border: none;
}

.otzivi_bottom_forms_button:hover {
    transform: scale(.9);
}

.otzivi_bottom_otzivi {
    margin-top: 40px;
}

.otzivi_bottom_otzivi_tittle {
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 23.8px */
}

.otzivi_bottom_otzivi_items {
    display: flex;
    flex-direction: column;
}

.otzivi_bottom_otzivi_item {
    margin-bottom: 16px;
    width: 100%;
    height: auto;
    border-radius: 8px;
    border: 1px solid #F7F7F7;
    background: #FFF;
    box-shadow: 0px 0px 7px 0px rgba(104, 107, 103, 0.07);
}

.otzivi_bottom_otzivi_item:first-child {
    margin-top: 24px;
}

.otzivi_bottom_otzivi_item:last-child {
    margin-bottom: 0px;
}

.otzivi_bottom_otzivi_item_content {
    padding: 16px;
}

.otzivi_bottom_otzivi_item_up {
    display: flex;
    align-items: center;
}

.otzivi_bottom_otzivi_item_up_name {
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.otzivi_bottom_otzivi_item_up_date {
    margin-left: auto;
    color: #C7C7C7;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 18px */
}

.otzivi_bottom_otzivi_item_text {
    margin-top: 16px;
    color: #535353;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 18px */
}


@media (min-width:320px) and (max-width:374px) {
    .otzivi_up_info_raiting {
        margin-left: 20px;
    }
}
</style>
