<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import TitleComponent from '@/components/TitleComponent.vue';
import DragonsComponent from '@/components/DragonsComponent.vue';
import { createFreeDragon } from '@/api/dragon';

onMounted(() => {
  document.body.classList.add('light');
});

// Удаляем класс при размонтировании

onUnmounted(() => {
  document.body.classList.remove('light');
});

const theme = ref<'light' | 'dark'>('dark');

const handleFreeDragonSelect = (dragonId: number) => {
  createFreeDragon({
    base_dragon_id: dragonId,
    name: dragonName.value
  });
};

const dragonName = ref('');
</script>

<template>
  <HeaderComponent :theme="theme" :clear="true" />
  <main>
    <TitleComponent
      v-model="dragonName"
      title="Выбор дракона"
      :isText="false"
      :isButton="false"
      :isInput="true"
      subtitle="Придумайте имя дракону"
      :isClose="false"
      placeholderInput="Введите имя"
    />
    
    <DragonsComponent 
      type="free" 
      title="Бесплатные драконы" 
      subtitle="Можете выбрать бесплатного дракона.
*Они приносят меньше монет чем платные"
     
    />

    <DragonsComponent 
      type="paid" 
      title="Платные драконы" 
      subtitle="Следующие драконы приносят больше монет, детальнее под каждым драконом."
     
    />

  </main>
</template>

<style></style>
