<template>
  <div class="deposit">
    <BonusComponent
      title='До 1000 рублей в день! <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.24244 16C12.5268 16 16 12.5268 16 8.24244C16 3.95805 12.5268 0.484863 8.24244 0.484863C3.95805 0.484863 0.484863 3.95805 0.484863 8.24244C0.484863 12.5268 3.95805 16 8.24244 16Z" fill="#F8ECDC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.75758 15.5152C12.042 15.5152 15.5152 12.042 15.5152 7.75758C15.5152 3.47319 12.042 0 7.75758 0C3.47319 0 0 3.47319 0 7.75758C0 12.042 3.47319 15.5152 7.75758 15.5152Z" fill="#FF7D04"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.7576 15.0306C11.7742 15.0306 15.0303 11.7744 15.0303 7.75782C15.0303 3.74119 11.7742 0.485077 7.7576 0.485077C3.74098 0.485077 0.484863 3.74119 0.484863 7.75782C0.484863 11.7744 3.74098 15.0306 7.7576 15.0306Z" fill="#FF7D04"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.07982 6.50244L7.75793 4.51962L6.43604 6.50244H9.07982Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.07982 6.94292L7.75793 12.4506L6.43604 6.94292H9.07982Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.6047 6.50244L11.2828 4.51962L9.96094 6.50244H12.6047Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.3206 6.94292L8.13477 12.4506L9.67677 6.94292L12.3206 6.94292Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.55492 6.50265L4.23303 4.51984L2.91113 6.50265H5.55492Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.77513 6.94292L7.31714 12.4506L3.13135 6.94292H5.77513Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.842 4.51951L9.52014 6.50232L8.19824 4.51951H10.842Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.31762 4.51951L5.99572 6.50232L4.67383 4.51951H7.31762Z" fill="white"/>
<g opacity="0.5">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.32757 2.70228C4.52844 2.70228 4.69127 2.53949 4.69127 2.33869C4.69127 2.13788 4.52844 1.9751 4.32757 1.9751C4.1267 1.9751 3.96387 2.13788 3.96387 2.33869C3.96387 2.53949 4.1267 2.70228 4.32757 2.70228Z" fill="white"/>
<path d="M3.46758 2.79086C3.81667 2.8105 3.90394 3.22044 3.51122 3.59401C2.98273 4.10722 2.58758 4.74819 2.31364 5.42213C2.31364 5.42213 2.2288 5.58043 2.08819 5.62431C1.81183 5.71134 1.53061 5.42358 1.67849 5.04831C2.02031 4.20056 2.53667 3.40734 3.24213 2.86141C3.30758 2.81486 3.34637 2.78989 3.46758 2.79086Z" fill="white"/>
</g>
</svg>
'
      subtitle='1₽ = 1 <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.24244 16C12.5268 16 16 12.5268 16 8.24244C16 3.95805 12.5268 0.484863 8.24244 0.484863C3.95805 0.484863 0.484863 3.95805 0.484863 8.24244C0.484863 12.5268 3.95805 16 8.24244 16Z" fill="#F8ECDC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.75758 15.5152C12.042 15.5152 15.5152 12.042 15.5152 7.75758C15.5152 3.47319 12.042 0 7.75758 0C3.47319 0 0 3.47319 0 7.75758C0 12.042 3.47319 15.5152 7.75758 15.5152Z" fill="#FF7D04"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.7576 15.0306C11.7742 15.0306 15.0303 11.7744 15.0303 7.75782C15.0303 3.74119 11.7742 0.485077 7.7576 0.485077C3.74098 0.485077 0.484863 3.74119 0.484863 7.75782C0.484863 11.7744 3.74098 15.0306 7.7576 15.0306Z" fill="#FF7D04"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.07982 6.50244L7.75793 4.51962L6.43604 6.50244H9.07982Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.07982 6.94292L7.75793 12.4506L6.43604 6.94292H9.07982Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.6047 6.50244L11.2828 4.51962L9.96094 6.50244H12.6047Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.3206 6.94292L8.13477 12.4506L9.67677 6.94292L12.3206 6.94292Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.55492 6.50265L4.23303 4.51984L2.91113 6.50265H5.55492Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.77513 6.94292L7.31714 12.4506L3.13135 6.94292H5.77513Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.842 4.51951L9.52014 6.50232L8.19824 4.51951H10.842Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.31762 4.51951L5.99572 6.50232L4.67383 4.51951H7.31762Z" fill="white"/>
<g opacity="0.5">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.32757 2.70228C4.52844 2.70228 4.69127 2.53949 4.69127 2.33869C4.69127 2.13788 4.52844 1.9751 4.32757 1.9751C4.1267 1.9751 3.96387 2.13788 3.96387 2.33869C3.96387 2.53949 4.1267 2.70228 4.32757 2.70228Z" fill="white"/>
<path d="M3.46758 2.79086C3.81667 2.8105 3.90394 3.22044 3.51122 3.59401C2.98273 4.10722 2.58758 4.74819 2.31364 5.42213C2.31364 5.42213 2.2288 5.58043 2.08819 5.62431C1.81183 5.71134 1.53061 5.42358 1.67849 5.04831C2.02031 4.20056 2.53667 3.40734 3.24213 2.86141C3.30758 2.81486 3.34637 2.78989 3.46758 2.79086Z" fill="white"/>
</g>
</svg>
'
      color="gray"
      type="deposit"
      :action="openBonusModal"
    />
    <BonusComponent
      title="Премиум"
      subtitle="Моментальный вывод без комиссии"
      color="pink"
      type="deposit"
      :action="handleClaim"
    />
    <!-- Модальное окно для бонуса -->
    <TransitionComponent>
      <ModalComponent
        v-if="isBonusModalOpen"
        :isOpen="isBonusModalOpen"
        @close="closeBonusModal"
      >
        <template #header>
          <div class="modal__header">
            <div class="header__left">
              <Icon name="RubSvg" />
            </div>
            <div class="header__content">
              <div class="header__texts">
                <span class="title">Пополнение</span>
                <span class="subtitle">Введите сумму</span>
              </div>
              <button @click="closeBonusModal"><Icon name="CloseSvg" /></button>
            </div>
          </div>
        </template>
        <template #body>
          <div class="modal__body">
            <div class="amounts">
              <div class="amount" @click="handleAmountClick(249)">249₽</div>
              <div class="amount" @click="handleAmountClick(399)">399₽</div>
              <div class="amount" @click="handleAmountClick(499)">499₽</div>
              <div class="amount" @click="handleAmountClick(649)">649₽</div>
              <div class="amount" @click="handleAmountClick(799)">799₽</div>
              <div class="amount" @click="handleAmountClick(999)">999₽</div>
            </div>
            <div class="modal__or-block">
              <div class="or-line"></div>
              <span class="or-text">или</span>
              <div class="or-line"></div>
            </div>
            <ButtonComponent class="without_submit"  @click="openAmountModal"
              >Вывести</ButtonComponent
            >
          </div>
        </template>
        
      </ModalComponent>
    </TransitionComponent>

     <!-- Модальное окно для бонуса -->
     <TransitionComponent>
      <ModalComponent
        v-if="isAmountModalOpen"
        :isOpen="isAmountModalOpen"
        @close="closeAmountModal"
      >
        <template #header>
          <div class="modal__header">
            <div class="header__left">
              <Icon name="RubSvg" />
            </div>
            <div class="header__content">
              <div class="header__texts">
                <span class="title">Пополнение</span>
                <span class="subtitle">Введите сумму</span>
              </div>
              <button @click="closeAmountModal"><Icon name="CloseSvg" /></button>
            </div>
          </div>
        </template>
        <template #body>
          <div class="modal__body">
            <InputGroupComponent v-model="amount" title="Сумма пополнения" placeholder="Введите сумму" type="number" :min="100" :max="10000000" />
            <ButtonComponent class="without_submit" @click="clickAmountButton"
              >Пополнить</ButtonComponent
            >
          </div>
        </template>
        
      </ModalComponent>
    </TransitionComponent>
  </div>
</template>

<script setup lang="ts">
import { ref, defineOptions } from 'vue';
import { useRouter } from 'vue-router';
import BonusComponent from './BonusComponent.vue';
import ModalComponent from './ModalComponent.vue';
import TransitionComponent from './TransitionComponent.vue';
import Icon from './IconComponent.vue';
import InputGroupComponent from './InputGroupComponent.vue';

import ButtonComponent from './ButtonComponent.vue';
defineOptions({
  name: 'DepositComponent',
});

const isAmountModalOpen = ref(false);

const closeAmountModal = () => {
  isAmountModalOpen.value = false;
};



const openAmountModal = () => {
  closeBonusModal();
  isAmountModalOpen.value = true;
};



const amount = ref(0);
const router = useRouter();

const handleAmountClick = (value: number) => {
  amount.value = value;
  router.push('/form/deposit'); // Перенаправляем на страницу /form/deposit
};

const clickAmountButton = () => {
  router.push('/form/deposit');
};

const isBonusModalOpen = ref(false);

const openBonusModal = () => {
  isBonusModalOpen.value = true;
};

const closeBonusModal = () => {
  isBonusModalOpen.value = false;
};

const handleClaim = () => {
  console.log('handleClaim');
};
</script>

<style scoped lang="scss">
.amounts {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .amount {
    @include body(var(--gray-100));
    padding: 12px 16px;
    cursor: pointer;

    border-radius: 40px;
    background-color: var(--primary-10);

    &:hover {
      background-color: var(--secondary-30);
    }
  }
}
.modal__or-block {
  display: flex;
  align-items: center; /* Центрирование по вертикали */
  justify-content: center; /* Центрирование по горизонтали */
  margin: 16px 0; /* Отступы сверху и снизу */
  
  .or-line {
    flex: 1; /* Занимает оставшееся пространство */
    height: 1px; /* Высота линии */
    background-color: var(--gray-20); /* Цвет линии */
    margin: 0 8px; /* Отступы слева и справа */
  }

  .or-text {
    font-size: 14px; /* Размер шрифта */
    color: var(--gray-20); /* Цвет текста */
    text-align: center; /* Центрирование текста */
  }
}
.deposit {
  display: flex;
  padding-top: 24px;
  flex-direction: column;
  gap: 16px;
}
.modal__header {
  @include contentCenter;
  gap: 16px;
  margin-bottom: 24px;
  .header__left {
    padding: 14px;
    background-color: var(--secondary-30);
    border-radius: 7px;
  }
  .header__content {
    width: 100%;
    @include contentCenter;
    justify-content: space-between;
    .header__texts {
      @include contentCenter;
      flex-direction: column;
      align-items: baseline;
      span {
        text-align: left;
      }
      .title {
        @include body(var(--gray-100));
      }
      .subtitle {
        @include smallText2(var(--gray-50));
      }
    }
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      align-self: baseline;
    }
  }
}
.modal__footer {
  @include contentCenter;
  @include smallText2(var(--gray-40));
  margin-top: 16px;
}

</style>
