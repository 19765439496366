<template>
  <header :class="['header', theme]">
    <div class="header__left">
      <router-link to="/"><DinosaurSvg /></router-link> 
      <router-link to="faq" v-if="!clear">Как заработать?</router-link>
    </div>

    <div class="header__right" v-if="!clear">
      <div v-for="(item, index) in headerItems" :key="index" :class="item.class" @click="toggleItem(item)">
        <component :is="item.icon" />
        <span v-if="item.text">{{ item.text }}</span>
        <TransitionComponent>
          <div :class="['tooltip', `tooltip__${item.name}`]" v-if="item.ref.value" @click="handleTooltipClick(item)">
            {{ item.tooltipText }}
          </div>
        </TransitionComponent>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { Ref, ref, defineProps, defineOptions } from "vue";
import { useRouter } from 'vue-router';
import DinosaurSvg from "@/components/Icons/DinosaurSvg.vue";
import NotificationSvg from "@/components/Icons/NotificationSvg.vue";
import GemSvg from "@/components/Icons/GemSvg.vue";
import ProfileSvg from "@/components/Icons/ProfileSvg.vue";
import TransitionComponent from "@/components/TransitionComponent.vue";

defineOptions({
  name: "HeaderComponent",
  
})

defineProps<{
  theme: "light" | "dark",
  clear?: boolean
}>();

const router = useRouter();

const showNotifications = ref(false);
const showBalance = ref(false);
const showProfile = ref(false);

const headerItems = [
  { name: 'notifications', class: 'notifications', icon: NotificationSvg, ref: showNotifications, tooltipText: 'У вас еще нет уведомлений' },
  { name: 'balance', class: 'balance_block', icon: GemSvg, ref: showBalance, text: '3,100', tooltipText: 'Пополнить', link: '/deposit' },
  { name: 'profile', class: 'profile_block', icon: ProfileSvg, ref: showProfile, tooltipText: 'Выйти' }
];

const toggleItem = (item: typeof headerItems[0]) => {
  item.ref.value = !item.ref.value;
};

const handleTooltipClick = (item: typeof headerItems[0]) => {
  if (item.name === 'balance') {
    router.push('/deposit');
  }
  // Здесь можно добавить обработку для других элементов, если необходимо
};
</script>

<style lang="scss">
.header {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  justify-content: space-between;
  
  &.light { --font-color: var(--gray-5); }
  &.dark { --font-color: var(--primary-100); }
  
  @include svg-fill(var(--font-color));
  
  &__left {
    display: flex;
    align-items: center;
    gap: 8px;
    a { @include secondaryDecoration(var(--font-color)); }
  }
  
  &__right {
    display: flex;
    align-items: center;
    gap: 8px;
    
    > div {
      @include contentCenter;
      
      position: relative;
      cursor: pointer;
    }
    
    .notifications{
      @include contentCenter;
      position: relative;
      cursor: pointer;
    }
    .profile_block{
      @include blockHeader;
    }
    .balance_block {
      @include blockHeader;
      width: 100%;
      gap: 8px;
      span {
        @include body(var(--gray-5));
        @include contentCenter;
      }
    }
  }
}
</style>
