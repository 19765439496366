<template>
  <div class="btn" @click="showWarning">
    <slot></slot>
  </div>
</template>

<script></script>

<style scoped lang="scss">
.btn {
  background-color: var(--primary-100);
  width: 100%;
  margin-top: 24px;
  border-radius: 100px;
  padding: 16px 0px;
  cursor: pointer;
  @include contentCenter;
  @include body(var(--white));
}
</style>
