// src/api/payments.ts
import axios from 'axios';
import { API_URL } from '@/config';

export interface Order {
  id: number;
  amount: number;
}

export const createOrder = async (data: { amount: number }): Promise<Order> => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/payments`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при создании заказа:', error);
    throw error;
  }
};

// Другие функции...
