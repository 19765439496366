// src/api/promocode.ts
import axios from 'axios';
import { API_URL } from '@/config';

export interface Promocode {
  id: number;
  code: string;
  discount: number;
}

export const activatePromocode = async (data: { code: string }): Promise<Promocode> => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/promocode/activate`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при активации промокода:', error);
    throw error;
  }
};

export const createPromocode = async (data: { code: string; discount: number }): Promise<Promocode> => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${API_URL}/promocode`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при создании промокода:', error);
    throw error;
  }
};

export const findAllPromocodes = async (): Promise<Promocode[]> => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/promocode`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при получении всех промокодов:', error);
    throw error;
  }
};

export const updatePromocode = async (id: number, data: any): Promise<Promocode> => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.patch(`${API_URL}/promocode/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при обновлении промокода:', error);
    throw error;
  }
};

export const deletePromocode = async (id: number): Promise<any> => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.delete(`${API_URL}/promocode/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Ошибка при удалении промокода:', error);
    throw error;
  }
};
